import { useContext } from 'react'

import { BadgeIcon, BodyText3, Button, Heading2, MicroText1 } from '@lumoslabs/lumosity-storybook'
import Link from 'next/link'
import styled from 'styled-components'

import { CardShadowRadius } from '~/components/ui/sharedStyledComponents'
import WorkoutDonut from '~/components/workout/WorkoutDonut'
import { AuthContext } from '~/context/AuthContext'
import { EventClick, EventScreenName } from '~/events/eventTypes'
import useTrackClick from '~/events/trackers/useTrackClick'
import { GetTodaysWorkoutQuery } from '~/graphql/generated/schema'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import ChevronRightIcon from '~/images/icons/SystemOutlined/ChevronRight.svg'
import GameGroupIcon from '~/images/icons/SystemOutlined/GameList.svg'
import WorkoutPlay from '~/images/icons/SystemOutlined/PlayCircle.svg'
import { getNextUnplayedGame, getTotalGamesCount, getTotalGamesPlayed } from '~/utils/workoutUtils'

export interface WorkoutCardProps {
  workout: GetTodaysWorkoutQuery
}

const WorkoutCard: React.FC<WorkoutCardProps> = ({ workout }) => {
  const t = useTranslationForNamespace('home')

  const { hasPremium } = useContext(AuthContext)
  const totalGameCount = getTotalGamesCount(hasPremium)

  const totalGamesPlayed = getTotalGamesPlayed(workout)

  const { trackCta } = useTrackClick()

  // Prevent the remaining game count going below 0 when the sub status goes from Premium to Free
  const remainingGameCount = totalGameCount - totalGamesPlayed < 0 ? 0 : totalGameCount - totalGamesPlayed

  const nextGame = getNextUnplayedGame(workout, hasPremium)?.game?.slug
  const gameUrl = nextGame ? `/workout` : '/'

  const workoutNotStarted = remainingGameCount === totalGameCount
  const CTA = workoutNotStarted ? 'begin' : 'continue'

  const handleClick = (text: string) => {
    trackCta({
      text: t(`common:buttons.${text}`),
      type: EventClick.types.Button,
      destination: EventScreenName.Pregame,
      click_name: EventClick.names.HomeWorkoutPlay,
    })
  }

  const WorkoutCardContent = () => {
    if (remainingGameCount === 0) {
      const headingText = t('workoutCard.headings.today.workoutComplete')
      const subheadingText = t('workoutCard.subheadings.today.workoutComplete')

      return (
        <Content>
          <InfoSection>
            <Heading2>{headingText}</Heading2>
            <BodyText3>{subheadingText}</BodyText3>
          </InfoSection>
        </Content>
      )
    } else {
      const headingText = t('workoutCard.headings.today.workoutIncomplete')

      // Don't include remains if workout hasn't been started
      const gamesRemainingText = workoutNotStarted
        ? t('workoutCard.subheadings.today.gamesRemainingInitial', { count: remainingGameCount })
        : t('workoutCard.subheadings.today.gamesRemaining', { count: remainingGameCount })

      return (
        <Content>
          <InfoSection>
            <Heading2>{headingText}</Heading2>
            <Details>
              <GameGroupIcon title='game_group_icon' fontSize='28px' />
              <MicroText1>{gamesRemainingText}</MicroText1>
            </Details>
          </InfoSection>
          <WorkoutLongButton href={gameUrl} passHref={true} prefetch={false} onClick={() => handleClick(CTA)}>
            <Button
              kind='primary'
              wide={true}
              rightBadge={
                <BadgeIcon>
                  <ChevronRightIcon className='button-icon' title={CTA} fontSize={'32px'} />
                </BadgeIcon>
              }
            >
              {t(`common:buttons.${CTA}`)}
            </Button>
          </WorkoutLongButton>
          <WorkoutIconButton href={gameUrl} passHref={true} prefetch={false} onClick={() => handleClick(CTA)}>
            <WorkoutPlayIcon title='workout-play-icon' fontSize={40} />
          </WorkoutIconButton>
        </Content>
      )
    }
  }

  return (
    <WorkoutCardContainer>
      <WorkoutDonutContainer>
        <WorkoutDonut workout={workout} />
      </WorkoutDonutContainer>
      <WorkoutCardContent />
    </WorkoutCardContainer>
  )
}

export default WorkoutCard

const WorkoutPlayIcon = styled(WorkoutPlay)`
  font-size: 40px;
  color: ${({ theme }) => theme.colorTokens.text['text-interactive']};
`

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
`

const WorkoutLongButton = styled(Link)`
  max-height: 52px;
  display: inline-flex;
  align-items: centre;
  width: min(100%, 240px);
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktopSmall}`} {
    display: none;
  }
`
const WorkoutIconButton = styled(Link)`
  display: none;
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktopSmall}`} {
    display: inline;
  }
`
const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  color: ${({ theme }) => theme.colorTokens.text['text-default']};
  padding: 32px 24px;
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktopSmall}`} {
    flex-direction: row;
    gap: 16px;
    padding: 24px 16px;
    align-items: center;
  }
`

const Details = styled.div`
  color: ${({ theme }) => theme.colors.neutral[300]};
  display: flex;
  align-items: center;
  gap: 8px;
`

const WorkoutCardContainer = styled(CardShadowRadius)`
  overflow: hidden;
  position: relative;
  max-width: min(100%, 592px);
  display: flex;
  flex-direction: row;
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktopSmall}`} {
    max-width: min(100%, 480px);
  }
`

const WorkoutDonutContainer = styled.div`
  width: min(33%, 200px);
  min-width: 100px;
  background-image: url('/assets/images/workout/workout-card-bg-color-burn.png');
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  background-position: 30% 75%;
  background-size: 235%;
  display: flex;
  justify-content: center;
  padding: 3%;
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktopSmall}`} {
    width: 100px;
  }
`
